<template>
  <div class="amap-page-container">
    <el-amap ref="map" vid="amapDemo" :center="center" :zoom="zoom" :plugin="plugin" :amap-manager="amapManager" class="amap-demo">
      <el-amap-marker
        v-for="(marker, index) in markerList"
        :key="index"
        :position="marker.position"
        :icon="marker.icon"
        :vid="index"
        :events="marker.events"
      ></el-amap-marker>
      <!-- <el-amap-polygon
        strokeColor="#00eeff"
        strokeOpacity="1"
        fillColor="#71B3ff"
        fillOpacity="0.5"
        v-for="(polygon, index) in polygons"
        :key="index+'polygons'"
        strokeWeight="2"
        :path="polygon.path"
      ></el-amap-polygon> -->
      <div class="del-box" v-if="windowShow">
        <div class="del-head">
          <img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E7%AB%96%E7%BA%BF.png" class="del-head-img">
          <div class="del-head-name">{{details.gridName}}</div>
          <img @click="clickClose()" src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E7%AE%AD%E5%A4%B4.png" class="del-head-img1">
        </div>
        <div class="del-context">
          <div class="del-one">
            <img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E5%A4%B4%E5%83%8F.png" class="del-one-img">
            <div class="del-one-tit">指导员</div>
          </div>
          <div class="one-item" style="width:100%" v-for="(item,index) in details.oneList" :key="index">
            <div>{{item.name}}：{{item.value}}</div>
          </div>
          <div class="del-one">
            <img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E5%A4%B4%E5%83%8F.png" class="del-one-img">
            <div class="del-one-tit">网格长</div>
          </div>
          <div class="one-item">{{details.gridOne}}</div>
          <div class="del-one">
            <img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E5%A4%B4%E5%83%8F.png" class="del-one-img">
            <div class="del-one-tit">网格员</div>
          </div>
          <div class="one-item" style="marginBottom:5%">{{details.gridTwo}}</div>
        </div>
      </div>
    </el-amap>
  </div>
</template>
<style lang="scss" scoped>
$px: 1rem/75;
.amap-page-container {
  height: 100%;
  position: relative;
}
.one-item:nth-child(odd){
  font-size: 30*$px;
  display: inline-block;
  width: 40%;
}
.one-item:nth-child(even){
  font-size: 30*$px;
  display: inline-block;
  width: 60%;
}
.del-box{
  position: absolute;
  z-index: 999;
  width: 94%;
  height: 600 * $px;
  background-color: #FFFFFF;
  bottom: 1%;
  margin-left: 3%;
  border-radius: 20 * $px;
}
.del-head{
  width: 96%;
  height: 12%;
  line-height: 70 * $px;
  margin-left: 2%;
  border-bottom: 1 * $px solid #e2e1e1;
  position: relative;
  margin-bottom:5 * $px;
}
.del-head-img{
  height: 60%;
}
.del-head-name{
  display: inline-block;
  font-size:36 * $px;
  margin-left: 15 * $px;
}
.del-head-img1{
  position: absolute;
  right: 2%;
  width: 60 * $px;
  top: 10%;
}
.del-context{
  height: 80%;
  margin: 0 2%;
  overflow-y: auto;
}
.del-one-img{
  width: 40*$px;
  margin-right:15*$px;
}
.del-one-tit{
  display: inline-block;
  font-size: 30*$px;
  vertical-align: middle;
}
.icon {
  position: absolute;
  bottom: 0.293rem;
  right: 0.24rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0.027rem 0.107rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.107rem;
  border: 0.013rem solid rgba(238, 238, 238, 1);
  padding: 0.16rem;
}
.topBorder {
  border-top: 0.013rem solid #e2e1e1;
}
.icon ul li {
  list-style: none;
  font-size: 10px;
  margin-top: 5px;
}

.icon ul li img {
  width: 22.4px;
  height: 22.4px;
}

.icon ul li p {
  margin: 0;
}
</style>
<style>
.amap-icon img {
  width: 30px;
  height: 30px;
}
.amap-logo{
  display: none;
  opacity: 0 !important;
}
.amap-copyright{
  display: none;
  opacity: 0 !important;
}
.amap-geo{
  display: none;
  opacity: 0 !important;
}
</style>
<script>
// NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
  key: '09ba3397bed53916f7419b24ae4a9707',
  plugin: ['AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geolocation',
    'AMap.Driving',],
  uiVersion: '1.0.11', // 版本号
  v: '1.4.4'
});
import { AMapManager } from 'vue-amap';
import {requestMap} from '../../api/map';
// CDN 方式
let amapManager = new VueAMap.AMapManager();
export default {
    computed: {
      getZoom() {
        return this.zoom > 12;
      }
    },
    data: function() {
      const center = [119.70448,29.015916];
      return {
        windowData:[],
        windowShow:false,
        markerList:[],
        amapManager,
        details:{},
        zoom: 12,
        center,
        lng: 0,
        lat: 0,
        loaded: false,
        plugin: [
            {
                pName: 'Geolocation',
                events: {
                    init(o) {
                    // o 是高德地图定位插件实例
                    // o.getCurrentPosition((status, result) => {
                    // 	if (result && result.position) {
                    // 		self.lng = result.position.lng;
                    // 		self.lat = result.position.lat;
                    // 		self.center = [self.lng, self.lat];
                    // 		self.loaded = true;
                    // 		self.$nextTick();
                    // 	}
                    // });
                    }
                }
            }
        ],
        // polygons:[
        //     {
        //         path: [
        //             [116.403322, 39.920255],
        //             [116.410703, 39.897555],
        //             [116.402292, 39.892353],
        //             [116.389846, 39.891365]
        //         ]
        //     }
        // ]
      };
    },
    mounted(){
      this.requestData()
      this.$nextTick(function () {
        const timer = setTimeout(function () {
          let o = amapManager.getMap();
          const yafanPoint1 = new AMap.LngLat(119.644179, 28.944977);
          const yafanPoint2 = new AMap.LngLat(119.767132, 29.052381);
          var imageLayer = new AMap.ImageLayer({
            bounds: new AMap.Bounds(yafanPoint1, yafanPoint2),
            url:
              'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E7%BC%96%E7%BB%84%208.png', // 图片 Url
            zIndex: 2,
            zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
          });
          o.add(imageLayer);
          const taoxiPoint1 = new AMap.LngLat(119.586349, 28.700247);
          const taoxiPoint2 = new AMap.LngLat(119.619065, 28.721889);
          var taoxiLayer = new AMap.ImageLayer({
            bounds: new AMap.Bounds(taoxiPoint1, taoxiPoint2),
            url:
              'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%A1%83%E6%BA%AA/%E6%A1%83%E6%BA%AA%E6%9C%89%E5%AD%97.png', // 图片 Url
            zIndex: 2,
            zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
          });
          // const layerList = [imageLayer, taoxiLayer];
          o.add(taoxiLayer);
          clearTimeout(timer);
        }, 1500);
      });
    },
    methods:{
      requestData(){
        const that=this
        requestMap({})
          .then(res => {
            if (res.success) {
              let a=res.data
              that.windowData=a.windowData
              that.markerInit(a.markers)
            } else {
              console.log(res.msg);
            }
          })
          .catch(err => {
            console.log(err);
          });
        // let a={
        //   markers:[
        //       {
        //         position: [119.644179, 28.944977],
        //         icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //         events: {}
        //       },
        //       {
        //         position: [119.684179, 28.944977],
        //         icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //         events: {}
        //       }
        //   ],
        //   windowData:[
        //       {
        //           oneList:[
        //               {
        //                   name:"联村干部",
        //                   value:"周启平"
        //               },
        //               {
        //                   name:"片区民警",
        //                   value:"周启平"
        //               },
        //               {
        //                   name:"法律顾问",
        //                   value:"周启平"
        //               }
        //           ],
        //           gridName:"雅畈一村网格",
        //           gridOne:"汤腾越",
        //           gridTwo:"汤腾越"
        //       },
        //       {
        //           oneList:[
        //               {
        //                   name:"联村干部",
        //                   value:"周启平"
        //               },
        //               {
        //                   name:"片区民警",
        //                   value:"周启平"
        //               },
        //               {
        //                   name:"法律顾问",
        //                   value:"周启平"
        //               }
        //           ],
        //           gridName:"雅畈二村网格",
        //           gridOne:"汤腾越",
        //           gridTwo:"汤腾越"
        //       },
        //   ]
        // }
      },
      markerInit(markers){
        // let markers=[
        //   {
        //     position: [120.001362, 27.384924],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.050512, 27.390532],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [119.99787, 27.472884],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.001388, 27.470744],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [119.996289, 27.471093],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.001486, 27.472212],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.001365, 27.470847],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [119.998726, 27.471675],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [119.507854, 29.793162],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.088121, 27.394398],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.087357, 27.384914],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.072239, 27.408188],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   },
        //   {
        //     position: [120.05234, 27.391285],
        //     icon: "https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E9%9B%85%E7%95%88/%E6%99%BA%E6%85%A7%E5%85%9A%E5%BB%BA%E7%BD%91%E6%A0%BC%E5%9C%B0%E5%9B%BE/%E6%92%92%E7%82%B9.png",
        //     events: {
        //       touchend(o) {
        //         console.log(o.target.w.vid);
        //       }
        //     }
        //   }
        // ]
        let markersAll = [];
        const this_=this
        markers.forEach((item,index) => {
          item.events = {
            click: () => {
              this_.lookText(item);
            },
          };
          item.id=index
          markersAll.push(item);
        });
        this_.markerList = markers;
      },
      lookText(marker){
        // console.log(marker)
        this.details=this.windowData[marker.id]
        this.windowShow=true
      },
      clickClose(){
        this.windowShow=false
      }
    }
};
</script>
