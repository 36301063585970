import request from '../request'

//请求列表
export const requestMap = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/pairing/gridInformation`,
        method: 'GET',
        headers: {
        }
    })
};